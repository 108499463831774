import { useState } from "react";
import context from "./context";

const ContextProvider = (props) => {
  const [trial, setTrial] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const value = { trial, setTrial, isModalOpen, setIsModalOpen };

  return <context.Provider value={value}>{props.children}</context.Provider>;
};

export default ContextProvider;
