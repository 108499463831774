import LockSolidIcon from "./icons/LockSolid";

const LockedOverlayIcon = () => {
  return (
    <div className="absolute top-0 left-0 backdrop-blur-sm bg-white/30 w-full h-full flex items-center justify-center">
      <div className="min-w-[40px] sm:min-w-[50px] min-h-[40px] sm:min-h-[50px] min-w-max rounded-full bg-slate-100 flex items-center justify-center text-[#B70000]">
        <LockSolidIcon />
      </div>
    </div>
  );
};

export default LockedOverlayIcon;
