import LockSolidIcon from "./icons/LockSolid";

const UnlockBanner = () => {
  return (
    <div className="flex flex-col sm:flex-row items-center gap-3 bg-white border border-slate-300 p-5 rounded-xl justify-between">
      <div className="flex flex-col sm:flex-row items-center gap-4 max-w-[400px]">
        <div className="min-w-[40px] sm:min-w-[50px] min-h-[40px] sm:min-h-[50px] min-w-max rounded-full bg-slate-100 flex items-center justify-center">
          <LockSolidIcon />
        </div>
        <p className="text-sm md:text-base font-semibold text-center sm:text-start text-slate-700">
          Analytics is Not Available on the FREE Plan. Upgrade to Gain Access.
        </p>
      </div>

      <button className="min-w-max bg-gradient-to-r from-[#C71E1E] to-[#3C40A5] text-white h-[50px] px-10 rounded-md font-semibold text-sm sm:text-base">
        ✦ Unlock Features
      </button>
    </div>
  );
};

export default UnlockBanner;
