import { useNavigate } from "react-router-dom";
import BackArrow from "../components/icons/BackArrow";
import UploadImage from "../components/UploadImage";
import ColorChanger from "../components/ColorChanger";
import { useState } from "react";
import EmailIcon from "../components/icons/Email";

const ThemeCustomization = () => {
  const navigate = useNavigate();

  const [color, setColor] = useState("#000000");
  const [imgUrl, setImgUrl] = useState(null);

  const [heading, setHeading] = useState("Heading Here");
  const [subHeading, setSubHeading] = useState("Sub Heading Here");

  return (
    <div className="w-full flex flex-col items-center gap-4 2xl:items-start 2xl:flex-row pt-[60px] pb-[30px]">
      <div className="w-full  flex flex-col items-center">
        <div className="flex items-start gap-2 h-[80px] w-full max-w-[500px]">
          <button
            onClick={() => {
              navigate("/dashboard/links");
            }}
            className="mt-0.5"
          >
            <BackArrow />
          </button>
          <div className="flex flex-col">
            <p className="text-xl font-semibold">
              Customize Your MailxTo Page.
            </p>
            <p>Your Link: mailxto.com/demo-link</p>
          </div>
        </div>
        <div className="h-[700px] w-full max-w-[500px] bg-white border border-slate-200 rounded-md p-8 flex flex-col items-center gap-5 justify-between shadow-md">
          <div className="flex flex-col items-center gap-3">
            <p>Logo</p>
            <div className="w-[120px] h-[120px] rounded-full relative">
              <div className="w-[120px] h-[120px] absolute top-0 bg-white rounded-full z-0">
                {imgUrl && (
                  <img
                    src={imgUrl}
                    alt="profile"
                    className="w-full h-full object-cover rounded-full"
                  />
                )}
              </div>
              <div className="w-[120px] h-[120px] rounded-full relative z-10 text-white">
                <UploadImage
                  onUpload={(val) => {
                    setImgUrl(val);
                  }}
                  circular={true}
                />{" "}
              </div>
            </div>
          </div>
          <div className="w-full flex flex-col gap-4">
            <div className="flex flex-col gap-2 w-full">
              <label className="text-sm">Heading</label>
              <input
                onChange={(e) => {
                  setHeading(e.target.value);
                }}
                value={heading}
                type="text"
                className="border-b border-b-slate-400 outline-none py-1 h-[40px]"
              />
            </div>
            <div className="flex flex-col gap-2 w-full">
              <label className="text-sm">Sub Heading</label>
              <input
                onChange={(e) => {
                  setSubHeading(e.target.value);
                }}
                value={subHeading}
                type="text"
                className="border-b border-b-slate-400 outline-none py-1  h-[40px]"
              />
            </div>
            <div className="w-full flex flex-col gap-2">
              <label className="text-sm">Primary Color</label>
              <ColorChanger
                onColorChange={(val) => {
                  setColor(val);
                }}
              />
            </div>
          </div>
          <button className="w-full h-[50px] bg-black text-white rounded">
            Save Changes
          </button>
        </div>
      </div>

      <div className="flex flex-col items-center w-full">
        <div className="h-[80px]">
          <p className="text-xl font-semibold">Preview</p>
        </div>
        <div className="h-[700px] w-full max-w-[500px] bg-white  border border-slate-200 rounded-md flex flex-col items-center overflow-hidden shadow-md">
          <div
            className="w-full flex flex-col items-center px-8 pt-8 pb-24 gap-4"
            style={{ backgroundColor: color }}
          >
            <div className="w-[120px] h-[120px] rounded-full border border-slate-400">
              {imgUrl && (
                <img
                  src={imgUrl}
                  alt="profile"
                  className="w-full h-full object-cover rounded-full"
                />
              )}
            </div>
            <div className="flex flex-col items-center gap-2 text-white">
              <p className="text-xl">{heading}</p>
              <p>{subHeading}</p>
            </div>
          </div>

          <div className="w-full px-5 flex items-center -mt-[50px]">
            <div className="w-full flex flex-col p-5 border border-slate-200 bg-white/70 backdrop-blur-sm rounded-md gap-2">
              <div className="flex flex-col gap-2 w-full">
                <label className="text-sm">To</label>
                <input
                  type="text"
                  className="border border-slate-400 outline-none h-[40px] rounded px-2"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label className="text-sm">Subject</label>
                <input
                  type="text"
                  className="border border-slate-400 outline-none h-[40px] rounded px-2"
                />
              </div>
              <div className="flex flex-col gap-2 w-full">
                <label className="text-sm">Body</label>
                <textarea
                  rows="2"
                  cols="40"
                  className="outline-none ring-0 border border-slate-400 resize-none rounded p-2"
                  placeholder="Write your content here"
                ></textarea>
              </div>

              <button
                style={{ background: color }}
                className="w-full h-[50px]  text-white rounded mt-[30px] text-lg font-semibold flex items-center justify-center gap-3"
              >
                <EmailIcon /> Compose Email
              </button>

              <div className="w-full relative flex items-center justify-center h-[10px] mt-1">
                <hr
                  style={{ borderTop: `1px solid ${color}` }}
                  className="w-full"
                />

                <span
                  style={{ color: color }}
                  className="text-[10px] absolute bg-white px-2"
                >
                  OR COMPOSE WITH
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ThemeCustomization;
