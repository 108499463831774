import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ContextProvider from "./store/ContextProvider";

import LandingPage from "./pages/LandingPage";
import Rootlayout from "./layouts/RootLayout";
import DashboardLayout from "./layouts/DashboardLayout";
import Links from "./pages/Links";
import Analytics from "./pages/Analytics";
import Forms from "./pages/Forms";
import Signatures from "./pages/Signatures";
import GenerateNewLink from "./pages/GenerateNewLink";
import ThemeCustomization from "./pages/ThemeCutomization";
import RegisterPage from "./pages/Register";
import LoginPage from "./pages/LoginPage";

function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Rootlayout />,
      // errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <LandingPage />,
        },
        {
          path: "register",
          element: <RegisterPage />,
        },
        {
          path: "login",
          element: <LoginPage />,
        },
      ],
    },
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      // errorElement: <ErrorPage />,
      children: [
        {
          index: true,
          element: <Links />,
        },
        {
          path: "links",
          element: <Links />,
        },
        {
          path: "analytics",
          element: <Analytics />,
        },
        {
          path: "forms",
          element: <Forms />,
        },
        {
          path: "signatures",
          element: <Signatures />,
        },
        {
          path: "newlink",
          element: <GenerateNewLink />,
        },
        {
          path: "themes",
          element: <ThemeCustomization />,
        },
      ],
    },
  ]);
  return (
    <ContextProvider>
      <RouterProvider router={router} />
    </ContextProvider>
  );
}

export default App;
