import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginUser } from "../services/auth.services";

import logo from "../assets/mailxto-logo.svg";

const LoginPage = () => {
  const navigate = useNavigate();
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });

  const updateLoginInfo = (label, e) => {
    setLoginInfo((prev) => {
      return { ...prev, [label]: e.target.value };
    });
  };
  const login = () => {
    loginUser(loginInfo)
      .then((res) => {
        console.log(res?.data?.token);
        if (res?.data?.success) {
          localStorage.setItem("usertoken", res?.data?.token);
          navigate("/dashboard/links");
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 min-h-screen w-full">
      <div className="flex flex-col min-h-screen items-center">
        <div className="flex w-[90%] py-10 flex justify-center ">
          <img className="w-40" src={logo} alt="logo" />
        </div>
        <div className="w-full flex flex-col flex-grow items-center justify-center">
          <div className="flex flex-col my-6 gap-4 w-[90%] max-w-[500px]">
            <div className="flex flex-col gap-2">
              <label>Email</label>
              <input
                className="outline-none h-[50px] bg-[#f6f7f5] px-3 rounded-md"
                placeholder="Email"
                type="email"
                onChange={(e) => {
                  updateLoginInfo("email", e);
                }}
              />
            </div>

            <div className="flex flex-col gap-2">
              <label>Password</label>
              <input
                className="outline-none h-[50px] bg-[#f6f7f5] px-3 rounded-md"
                placeholder="Password"
                type="password"
                onChange={(e) => {
                  updateLoginInfo("password", e);
                }}
              />
            </div>
            <button
              className="bg-black text-white rounded-full h-[50px] font-medium mt-5"
              onClick={login}
            >
              Login
            </button>
          </div>
        </div>
      </div>
      <div className="bg-black hidden sm:flex"></div>
    </div>
  );
};

export default LoginPage;
