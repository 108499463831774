import HttpService from "./http.services";
import { REGISTER_URL, LOGIN_URL } from "./url.services";

const { post } = HttpService;

export const registerUser = (data) => {
  return post(REGISTER_URL, data);
};

export const loginUser = (data) => {
  return post(LOGIN_URL, data);
};
