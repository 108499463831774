import { useState } from "react";

const ColorChanger = ({ onColorChange }) => {
  const [color, setColor] = useState("#000000");
  const handleColorChange = (event) => {
    setColor(event.target.value);
    onColorChange(event.target.value);
  };

  return (
    <div className="flex items-center rounded overflow-hidden">
      <input
        value={color}
        onChange={handleColorChange}
        type="color"
        className="appearance-none w-[70px] h-12 p-0 border-none"
      />
      <input
        type="text"
        style={{ border: `2px solid ${color}` }}
        className="w-full px-2 h-10 py-0  outline-none -ml-1"
        value={color}
        onChange={(e) => {
          setColor(e.target.value);
        }}
      />
    </div>
  );
};

export default ColorChanger;
