import { useEffect, useRef, useState } from "react";
import DownloadIcon from "./icons/Download";
import QRCodeStyling from "qr-code-styling";
import UploadImage from "./UploadImage";
import ColorChanger from "./ColorChanger";

const QRcodeCustomization = () => {
  const [color, setColor] = useState("#000000");
  const [url] = useState("https://qr-code-styling.com");
  const [img, setImg] = useState("");
  const [fileExt, setFileExt] = useState("png");
  const ref = useRef(null);

  const qrCode = useRef(
    new QRCodeStyling({
      width: 300,
      height: 300,
      image: img,
      dotsOptions: {
        color: color,
        type: "rounded",
      },
      imageOptions: {
        crossOrigin: "anonymous",
        margin: 20,
      },
      data: url,
    })
  );

  const getImage = (val) => {
    setImg(val);
  };

  useEffect(() => {
    qrCode.current.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.current.update({
      data: url,
      dotsOptions: {
        color: color,
      },
    });
  }, [url, color]);

  useEffect(() => {
    qrCode.current.update({
      image: img,
    });
  }, [img]);

  const onExtensionChange = (event) => {
    setFileExt(event.target.value);
  };

  const onDownloadClick = () => {
    qrCode.current.download({
      extension: fileExt,
    });
  };

  return (
    <div className="flex flex-col ">
      <div className="flex flex-col items-center gap-3 py-5">
        <p className="font-bold text-xl">Customize & Download QR Code</p>
        <p>mailxto.com/demo-link</p>
      </div>
      <div className=" w-full bg-[#F0F0F0] p-8 flex flex-col gap-8">
        <div className="flex flex-col sm:flex-row md:h-[300px] gap-8">
          <div className="w-[240px] h-[240px] sm:w-[300px] sm:h-[300px]  bg-slate-200 rounded-md flex items-center justify-center">
            <div ref={ref} />
          </div>
          <div className="flex flex-col flex-grow gap-4">
            <div className="flex flex-col gap-2">
              <p className="font-semibold">Foreground Color</p>
              <ColorChanger
                onColorChange={(val) => {
                  setColor(val);
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-semibold">Logo</p>
              <div className="h-[50px] w-full bg-slate-400 rounded-md"></div>
            </div>
            <div className="bg-slate-200 border h-[100px] md:h-auto  rounded-md flex flex-grow w-full mt-2 items-center justify-center">
              <UploadImage onUpload={getImage} />
            </div>
          </div>
        </div>
        <div className="relative w-full">
          <button
            onClick={onDownloadClick}
            className="h-[50px] w-full bg-black text-white flex items-center justify-center gap-5 rounded-md "
          >
            <DownloadIcon />
            Download QR Code
          </button>
          <button
            onClick={(e) => {
              e.preventDefault();
            }}
            className="absolute top-0 right-3 h-[50px] z-10"
          >
            <select
              onChange={onExtensionChange}
              value={fileExt}
              className="h-full bg-black text-white outline-none border-none text-sm"
            >
              <option value="png">PNG</option>
              <option value="jpeg">JPEG</option>
              <option value="webp">WEBP</option>
            </select>
          </button>
        </div>
      </div>
    </div>
  );
};

export default QRcodeCustomization;
