import { NavLink } from "react-router-dom";
import logo from "../assets/mailxto-logo.svg";

import LinkIcon from "../components/icons/Link";
import AnalyticsIcon from "./icons/Analytics";
import FormIcon from "./icons/Form";
import SignatureIcon from "./icons/Signature";

const LeftsideBar = () => {
  const linkActive =
    window.location.pathname.includes("newlink") ||
    window.location.pathname.includes("themes");
  return (
    <div className="h-full w-full  flex flex-col items-center pb-4 pr-10">
      <div className="w-full pl-10 pt-[50px] pb-[20px]">
        <img src={logo} alt="mailxto logo" className="w-48 mb-6" />
      </div>

      <nav className="w-full flex flex-col items-center">
        <NavLink
          to="links"
          className={({ isActive }) =>
            isActive || linkActive
              ? "w-full p-3 bg-[#9C9C9C21]  rounded-tr-full rounded-br-full flex items-center gap-3 pl-10 font-bold"
              : "w-full p-3 text-gray-800  hover:bg-slate-50 rounded-tr-full rounded-br-full flex items-center gap-3 pl-10"
          }
        >
          <LinkIcon /> Links
        </NavLink>
        <NavLink
          to="analytics"
          className={({ isActive }) =>
            isActive
              ? "w-full p-3 bg-[#9C9C9C21] rounded-tr-full rounded-br-full flex items-center gap-3 pl-10 font-bold"
              : "w-full p-3 text-gray-800 hover:bg-slate-50 rounded-tr-full rounded-br-full flex items-center gap-3 pl-10"
          }
        >
          <AnalyticsIcon /> Analytics
        </NavLink>
        <NavLink
          to="forms"
          className={({ isActive }) =>
            isActive
              ? "w-full p-3 bg-[#9C9C9C21] rounded-tr-full rounded-br-full flex items-center gap-3 pl-10 font-bold"
              : "w-full p-3  text-gray-800 hover:bg-slate-50 rounded-tr-full rounded-br-full flex items-center gap-3 pl-10"
          }
        >
          <FormIcon /> Forms
        </NavLink>
        <NavLink
          to="signatures"
          className={({ isActive }) =>
            isActive
              ? "w-full p-3 bg-[#9C9C9C21] rounded-tr-full rounded-br-full flex items-center gap-3 pl-10 font-bold"
              : "w-full p-3  text-gray-800 hover:bg-slate-50 rounded-tr-full rounded-br-full flex items-center gap-3 pl-10"
          }
        >
          <SignatureIcon /> Signatures
        </NavLink>
      </nav>
    </div>
  );
};

export default LeftsideBar;
