import HttpService from "./http.services";

import { LINKS_URL, NEW_LINK_URL, UPDATE_LINKS_URL } from "./url.services";

const { post, get, patch } = HttpService;

export const createNewLink = (data) => {
  return post(NEW_LINK_URL, data);
};

export const getLinks = (data) => {
  return get(LINKS_URL, data);
};

export const updateLinks = (data, id) => {
  return patch(UPDATE_LINKS_URL, data, id);
};
