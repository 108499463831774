const LandingPage = () => {
  return (
    <div className="bg-red-100 w-full flex flex-col">
      <div className="bg-[#ebedfc] h-[300px] flex flex-col items-center justify-center">
        <h1 className="font-thick text-center text-[#25335C] xxs:text-md xxs:font-semibold xs:text-xl xs:font-semibold sm:text-2xl md:text-3xl lg:text-4xl ">
          Mailto Link Generator <span className="text-blue-500">.</span>
        </h1>
        <p className="font-normal text-center text-[#25335C] xxs:text-sm p-2  sm:text-base md:text-xl">
          Generate a short mailto link that can be shared on <br /> social
          medias easily and embed it to your website.
        </p>
      </div>
    </div>
  );
};

export default LandingPage;
