import { NavLink } from "react-router-dom";
import AnalyticsIcon from "./icons/Analytics";
import LinkIcon from "./icons/Link";
import FormIcon from "./icons/Form";
import SignatureIcon from "./icons/Signature";

const BottomNav = () => {
  return (
    <nav className="max-h-[100px] w-full bg-white lg:hidden flex items-center pt-2 border-t border-t-gray-200 px-2">
      <NavLink
        to="links"
        className={({ isActive }) =>
          isActive || window.location.pathname.includes("newlink")
            ? "w-full p-2 bg-slate-100  flex flex-col items-center gap-3  text-sm"
            : "w-full p-2 text-gray-800  hover:bg-slate-50   flex flex-col items-center gap-3  text-sm"
        }
      >
        <LinkIcon /> Links
      </NavLink>
      <NavLink
        to="analytics"
        className={({ isActive }) =>
          isActive
            ? "w-full p-2 bg-[#9C9C9C21]   flex flex-col items-center gap-3  text-sm"
            : "w-full p-2 text-gray-800 hover:bg-slate-50   flex flex-col items-center gap-3  text-sm"
        }
      >
        <AnalyticsIcon /> Analytics
      </NavLink>
      <NavLink
        to="forms"
        className={({ isActive }) =>
          isActive
            ? "w-full p-2 bg-[#9C9C9C21]   flex flex-col items-center gap-3  text-sm"
            : "w-full p-2  text-gray-800 hover:bg-slate-50   flex flex-col items-center gap-3  text-sm"
        }
      >
        <FormIcon /> Forms
      </NavLink>
      <NavLink
        to="signatures"
        className={({ isActive }) =>
          isActive
            ? "w-full p-2 bg-[#9C9C9C21]   flex  flex-col items-center gap-3  text-sm"
            : "w-full p-2  text-gray-800 hover:bg-slate-50   flex flex-col items-center gap-3  text-sm"
        }
      >
        <SignatureIcon /> Signatures
      </NavLink>
    </nav>
  );
};

export default BottomNav;
