import context from "../store/context";
import { useContext } from "react";

const Modal = ({ children }) => {
  const { setIsModalOpen } = useContext(context);

  return (
    <div className="w-screen h-screen fixed top-0 left-0  flex items-center justify-center z-50">
      <div
        onClick={() => {
          setIsModalOpen(false);
        }}
        className="w-full h-full bg-black/50 backdrop-blur-sm"
      ></div>
      <div className="w-[95%] max-w-[800px]  max-h-[90vh] bg-white rounded-md absolute overflow-y-auto">
        {children}
      </div>
    </div>
  );
};

export default Modal;
