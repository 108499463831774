import { Outlet } from "react-router-dom";

import LeftsideBar from "../components/LeftsideBar";
import BottomNav from "../components/BottomNav";

const DashboardLayout = () => {
  return (
    <div className="flex flex-col lg:flex-row w-full h-screen">
      {/* {isModalOpen && <Modal>hello</Modal>} */}
      <div className="flex-col items-center w-[400px] bg-white  hidden lg:flex">
        <LeftsideBar />
      </div>
      <main
        className={`flex flex-col flex-grow items-center w-full bg-slate-100 px-3 sm:5 md:px-[40px] xl:px-[100px]  overflow-y-auto`}
      >
        <Outlet />
      </main>
      <BottomNav />
    </div>
  );
};

export default DashboardLayout;
