import { useNavigate } from "react-router-dom";
import BackArrow from "../components/icons/BackArrow";
import LinkForm from "../components/LinkForm";

const GenerateNewLink = () => {
  const navigate = useNavigate();
  return (
    <div className="w-full ">
      <div className="w-full  flex flex-col gap-8">
        <div className="flex items-start gap-2 pt-[60px]">
          <button
            onClick={() => {
              navigate("/dashboard/links");
            }}
            className="mt-0.5"
          >
            <BackArrow />
          </button>
          <div className="flex flex-col">
            <p className="text-xl font-semibold">Generate New Link</p>
            <p>Fill in the details to generate a new mailto link.</p>
          </div>
        </div>

        <LinkForm />
      </div>
    </div>
  );
};

export default GenerateNewLink;
