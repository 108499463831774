import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import CameraIcon from "./icons/Camera";
import UploadIcon from "../components/icons/Upload";

const UploadImage = ({ onUpload, circular = false, forSocial = false }) => {
  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      onUpload(reader.result);
      console.log(reader.result);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      {...getRootProps()}
      className={`flex items-center justify-center w-full ${
        forSocial ? "h-[200px]" : "h-full"
      }  p-4 border-2 border-dashed border-slate-300 rounded  cursor-pointer ${
        circular ? "rounded-full" : "bg-gray-100"
      }`}
    >
      <input {...getInputProps()} />
      {!circular ? (
        isDragActive ? (
          <p className="text-gray-500">Drop the files here...</p>
        ) : (
          <div className="flex flex-col items-center gap-3 text-center">
            {forSocial && (
              <div className="text-slate-500">
                <UploadIcon />
              </div>
            )}
            {forSocial && (
              <p className="text-sm text-[#818cf8]">
                DRAG AND DROP OR{" "}
                <span className="text-[#6366f1] font-bold">CLICK HERE</span> TO
                UPLOAD
              </p>
            )}
            <p className="text-gray-500 cursor-pointer text-[11px]">
              {" "}
              {forSocial
                ? "Recommended:1200 x 630 pixels | (Max 5 MB)"
                : "Upload Your Own"}
            </p>
          </div>
        )
      ) : (
        <div className="w-full h-full bg-black/40 rounded-full flex items-center justify-center ">
          <CameraIcon />
        </div>
      )}
    </div>
  );
};

export default UploadImage;
