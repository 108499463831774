import { Outlet, useLocation } from "react-router-dom";
import NavigationBar from "../components/NavigationBar";
import Footer from "../components/Footer";

const Rootlayout = () => {
  const location = useLocation();
  const hideNavAndFooter =
    location.pathname.startsWith("/register") ||
    location.pathname.startsWith("/login");
  return (
    <div className="flex flex-col items-center w-full">
      <div className="flex flex-col items-center  min-h-screen w-full">
        {!hideNavAndFooter && <NavigationBar />}
        <main
          className={`flex flex-col flex-grow items-center w-full"
          `}
        >
          <Outlet />
        </main>
      </div>

      {!hideNavAndFooter && <Footer />}
    </div>
  );
};

export default Rootlayout;
