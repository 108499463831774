import AnalysisLabel from "../components/AnalysisLabel";
import DropdownSearch from "../components/DropdownSearch";
import GeneralStat from "../components/GeneralStat";
import LockedOverlayIcon from "../components/LockedOverlay";
import UnlockBanner from "../components/UnlockBanner";

const data = [
  { id: 1, link: "https://example.com/1" },
  { id: 2, link: "https://example.com/2" },
  { id: 3, link: "https://example.com/3" },
  { id: 4, link: "https://example.com/4" },
  { id: 5, link: "https://example.com/5" },
  { id: 6, link: "https://example.com/6" },
  { id: 7, link: "https://example.com/7" },
  { id: 8, link: "https://example.com/8" },
  { id: 9, link: "https://example.com/9" },
  { id: 10, link: "https://example.com/10" },
];

const Analytics = () => {
  return (
    <div className="min-h-full w-full">
      <div className="flex flex-col sm:flex-row items-center justify-between gap-2 sticky top-0 left-0 z-40 pt-[20px] md:pt-[40px] pb-4 md:pb-6  bg-slate-100">
        <div className="w-full">
          <p className="text-2xl font-bold">Analytics</p>
          <p className="text-sm">ANALYTICS FOR MAILXTO.COM/DEMO-LINK</p>
        </div>

        <div className="w-full sm:w-[350px] flex flex-col relative">
          <DropdownSearch list={data} />
        </div>
      </div>

      <div className="w-full flex flex-col gap-5 pb-6">
        <UnlockBanner />
        <GeneralStat />

        <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-8">
          <div className="flex flex-col  sm:col-span-2 gap-2">
            <AnalysisLabel label={"Click Overviews"} />
            <div className="rounded-xl border border-slate-300 p-5 bg-white h-[350px] relative overflow-hidden">
              <LockedOverlayIcon />
              hello
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <AnalysisLabel label={"Referrers"} />
            <div className="rounded-xl border border-slate-300 p-5 bg-white h-[350px] relative overflow-hidden">
              <LockedOverlayIcon />
              hello
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <AnalysisLabel label={"Countries"} />
            <div className="rounded-xl border border-slate-300 p-5 bg-white h-[350px] relative overflow-hidden">
              <LockedOverlayIcon />
              hello
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <AnalysisLabel label={"Devices"} />
            <div className="rounded-xl border border-slate-300 p-5 bg-white h-[350px] relative overflow-hidden">
              <LockedOverlayIcon />
              hello
            </div>
          </div>

          <div className="flex flex-col gap-2">
            <AnalysisLabel label={"Browsers"} />
            <div className="rounded-xl border border-slate-300 p-5 bg-white h-[350px] relative overflow-hidden">
              <LockedOverlayIcon />
              hello
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
