import GeneralStatCard from "./cards/GeneralStatCard";
import ClickIcon from "./icons/Click";
import GlobIcon from "./icons/Glob";
import ReferIcon from "./icons/Refer";

const GeneralStat = () => {
  return (
    <div className="w-full p-2 border border-slate-300 grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 bg-white rounded-xl flex divide-y divide-dashed divide-y-slate-100 sm:divide-none">
      <GeneralStatCard
        icon={<ClickIcon />}
        label={"Total Clicks"}
        figure={4533}
      />
      <GeneralStatCard
        icon={<ReferIcon />}
        label={"Total Referrers"}
        figure={45}
      />
      <GeneralStatCard
        icon={<GlobIcon />}
        label={"Total Countries"}
        figure={100}
      />
      <GeneralStatCard
        icon={<ClickIcon />}
        label={"Total Browsers"}
        figure={4533}
      />
    </div>
  );
};

export default GeneralStat;
