const GeneralStatCard = ({ icon, label, figure }) => {
  return (
    <div className="flex sm:flex-col items-center justify-between gap-5 p-5 xl:py-10 ">
      <div className="flex items-center gap-3 text-[#4882DE]">
        {icon} <p className="text-black">{label}:</p>
      </div>
      <p className="font-bold text-base sm:text-2xl">{figure}</p>
    </div>
  );
};

export default GeneralStatCard;
