import { useState } from "react";
import { createNewLink, updateLinks } from "../services/link.services";

const LinkForm = ({ formCase, data }) => {
  console.log(data, "d");
  const [addCc, setAddCc] = useState(false);

  const [linkData, setLinkData] = useState({
    email: data?.email || "",
    cc_email: data?.cc_email || "",
    subject: data?.subject || "",
    body: data?.body || "",
    custom_slug: data?.custom_slug || "",
    show_preview: true,
    smart_fill: true,
  });

  const updateLinkData = (label, e) => {
    setLinkData((prev) => {
      return { ...prev, [label]: e.target.value };
    });
  };

  const genrateLink = () => {
    createNewLink(linkData)
      .then((res) => {
        console.log(res?.data);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="w-full bg-white rounded-md px-10 pt-10 flex flex-col gap-14">
      <div className="flex w-full border-b border-b-slate-300">
        <p className="font-bold">To:</p>
        <input
          type="text"
          className="w-full outline-none ring-0 border-none px-2"
          value={linkData?.email}
          onChange={(e) => {
            updateLinkData("email", e);
          }}
        />
        {!addCc && (
          <button
            onClick={() => {
              setAddCc(true);
            }}
          >
            cc
          </button>
        )}
      </div>
      {addCc && (
        <div className="flex w-full border-b border-b-slate-300">
          <p className="font-bold">Cc:</p>
          <input
            type="text"
            className="w-full outline-none ring-0 border-none px-2"
            value={linkData?.cc_email}
            onChange={(e) => {
              updateLinkData("cc_email", e);
            }}
          />

          <button
            className="min-w-max"
            onClick={() => {
              setAddCc(false);
            }}
          >
            - cc
          </button>
        </div>
      )}
      <div className="flex w-full border-b border-b-slate-300">
        <p className="font-bold">Subject:</p>
        <input
          type="text"
          className="w-full outline-none ring-0 border-none px-2"
          value={linkData?.subject}
          onChange={(e) => {
            updateLinkData("subject", e);
          }}
        />
      </div>

      <div className="flex flex-col gap-3">
        <label className="font-bold">Body</label>

        <textarea
          rows="6"
          cols="40"
          className="outline-none ring-0 border-b border-b-slate-300 resize-none"
          placeholder="Write your content here"
          value={linkData?.body}
          onChange={(e) => {
            updateLinkData("body", e);
          }}
        ></textarea>
      </div>

      <div className="flex flex-col gap-1">
        <label>Custom short link ID</label>
        <div className="flex w-full border-b border-b-slate-300">
          <p>Mailxto.com/</p>
          <input
            type="text"
            className="w-full outline-none ring-0 border-none"
            value={linkData?.custom_slug}
            onChange={(e) => {
              updateLinkData("custom_slug", e);
            }}
          />
        </div>
      </div>
      <div className="pt-2 pb-10 bg-white sticky bottom-0">
        <button
          onClick={() => {
            console.log(linkData);

            if (formCase === "edit") {
              updateLinks(linkData, data?.id);
            } else {
              genrateLink();
            }
          }}
          className="bg-black text-white font-bold h-[50px] w-full rounded-sm"
        >
          {formCase === "edit" ? "Update Link Content" : "Generate New Link"}
        </button>
      </div>
    </div>
  );
};

export default LinkForm;
