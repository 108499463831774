import "../../shimmer.css";

const LinksCardShimmer = () => {
  return (
    <div className="border border-slate-200 rounded-md bg-white p-5 h-[180px] flex flex-col divide-y divide-y-slate-200 ">
      <div className="pb-5 flex flex-col gap-4">
        <div className="flex items-center gap-1 sm:gap-2">
          <p className="text-normal sm:text-xl font-semibold rounded-full h-6 w-[300px] animate-pulse-gradient"></p>
        </div>
        <div className="flex items-center text-sm sm:text-normal gap-2">
          <p className="text-normal sm:text-xl font-semibold rounded-full h-4 w-[100px] animate-pulse-gradient"></p>
        </div>
      </div>
      <div className="flex items-center justify-between pt-3">
        <div className="flex items-center gap-4">
          <div className="size-7 animate-pulse-gradient rounded-md"></div>
          <div className="size-7 animate-pulse-gradient rounded-md"></div>
          <div className="size-7 animate-pulse-gradient rounded-md"></div>
        </div>
        <button className="bg-gray-200 text-black px-3 sm:px-5 py-2 w-[100px] h-[40px] rounded-full flex items-center gap-1 sm:gap-3 text-sm sm:text-normal animate-pulse-gradient"></button>
      </div>
    </div>
  );
};

export default LinksCardShimmer;
