import { useState } from "react";
import ArrowDown from "./icons/ArrowDown";

const DropdownSearch = ({ list }) => {
  const [showDropDownContent, setShowDropDownContent] = useState(false);
  return (
    <div className="w-full flex flex-col relative">
      <button
        onClick={() => {
          setShowDropDownContent(!showDropDownContent);
        }}
        className="border border-slate-300 text-black px-4 h-[40px] w-full rounded-md text-sm md:text-md flex items-center justify-between gap-3 bg-white"
      >
        mailxto.com/demo-link{" "}
        <div className={showDropDownContent ? "rotate-180	" : ""}>
          <ArrowDown />
        </div>
      </button>
      {showDropDownContent && (
        <div className="w-full bg-white absolute top-[43px] px-2 rounded border border-slate-200 shadow-lg">
          <input
            type="text"
            className="w-full outline-none border border-slate-200 h-[35px] px-2 my-2 rounded-md"
            placeholder="search"
          />
          <div className="flex flex-col h-[200px] overflow-y-auto mb-2 divide-y divide-y-slate-50">
            {list?.map((item) => {
              return (
                <div className="py-2 cursor-pointer">
                  <p className="text-sm text-slate-600">{item.link}</p>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSearch;
