import { useState } from "react";
import { registerUser } from "../services/auth.services";

import logo from "../assets/mailxto-logo.svg";

const RegisterPage = () => {
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
  });

  const updateUserData = (label, e) => {
    setUserData((prev) => {
      return { ...prev, [label]: e.target.value };
    });
  };

  const register = () => {
    registerUser(userData)
      .then((res) => {
        console.log(res?.data?.token);
      })
      .catch((err) => console.log(err));
  };
  return (
    <div className="w-full min-h-screen grid grid-cols-1 md:grid-cols-2">
      <div className="flex flex-col min-h-screen items-center">
        <div className="flex w-[90%] py-10 flex justify-center ">
          <img className="w-40" src={logo} alt="logo" />
        </div>
        <div className="w-full flex flex-col flex-grow items-center justify-center">
          <div className="flex flex-col my-6 gap-4 w-[90%] max-w-[500px]">
            <div className="flex flex-col gap-2">
              <label>Name</label>
              <input
                className="outline-none h-[50px] bg-[#f6f7f5] px-3 rounded-md"
                placeholder="Name"
                onChange={(e) => {
                  updateUserData("name", e);
                }}
              />
            </div>

            <div className="flex flex-col gap-2">
              <label>Email</label>
              <input
                className="outline-none h-[50px] bg-[#f6f7f5] px-3 rounded-md"
                placeholder="Email"
                type="email"
                onChange={(e) => {
                  updateUserData("email", e);
                }}
              />
            </div>

            <div className="flex flex-col gap-2">
              <label>Password</label>
              <input
                className="outline-none h-[50px] bg-[#f6f7f5] px-3 rounded-md"
                placeholder="Password"
                type="password"
                onChange={(e) => {
                  updateUserData("password", e);
                }}
              />
            </div>
            <button
              className="bg-black text-white rounded-full h-[50px] font-medium mt-5"
              onClick={register}
            >
              Register
            </button>
          </div>
        </div>
      </div>

      <div className="bg-black hidden md:flex"></div>
    </div>
  );
};

export default RegisterPage;
