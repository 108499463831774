import { useNavigate } from "react-router-dom";
import LinksCard from "../components/cards/LinksCard";
import Modal from "../components/Modal";

import context from "../store/context";
import { useContext, useEffect, useState } from "react";
import LinkForm from "../components/LinkForm";
import QRcodeCustomization from "../components/QRcodeCustomization";
import SocialMediaCardCustomizer from "../components/SocialMediaCardCustomizer";
import { getLinks } from "../services/link.services";
import LinksCardShimmer from "../components/shimmer/LinksCardShimmer";

const Links = () => {
  const navigate = useNavigate();

  const { isModalOpen } = useContext(context);

  const [clickedLinkData, setClickedLinkData] = useState(null);
  const [modalContent, setModalContent] = useState(null);

  const getClickedLinkData = (val, mc) => {
    setModalContent(mc);
    setClickedLinkData(val);
    console.log(val);
  };

  const [linksList, setLinksList] = useState([]);

  useEffect(() => {
    getLinks()
      .then((res) => {
        setLinksList(res?.data?.links);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <div className="w-full flex flex-col flex-grow  pb-7">
      {isModalOpen && (
        <Modal>
          {modalContent === "edit_form" ? (
            <LinkForm formCase="edit" data={clickedLinkData} />
          ) : modalContent === "socials" ? (
            <SocialMediaCardCustomizer />
          ) : (
            <QRcodeCustomization />
          )}
        </Modal>
      )}
      <div className="flex items-center justify-between sticky top-0 left-0 pt-4 md:pt-[40px] pb-4 md:pb-6 z-40 bg-slate-100">
        <p className="text-2xl font-bold">My Mailto Links</p>
        <button
          onClick={() => {
            navigate("/dashboard/newlink");
          }}
          className="bg-black text-white px-5 sm:px-12 py-3 rounded-full text-sm md:text-md"
        >
          Generate New Links
        </button>
      </div>

      <div className="flex flex-col flex-grow gap-3">
        {linksList &&
          linksList?.map((link, index) => {
            return (
              <LinksCard
                key={index}
                linkInfo={link}
                getClickedLinkData={getClickedLinkData}
              />
            );
          })}
        {linksList.length === 0 && (
          <div className="flex flex-col flex-grow gap-3">
            <LinksCardShimmer />
            <LinksCardShimmer />
            <LinksCardShimmer />
            <LinksCardShimmer />
          </div>
        )}
      </div>
    </div>
  );
};

export default Links;
