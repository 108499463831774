import { useState } from "react";
import UploadImage from "../components/UploadImage";
const SocialMediaCardCustomizer = () => {
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");

  const [imgUrl, setImgUrl] = useState("");

  const initialTitle = "This is a test email too";
  const initialDesc =
    "Customize Social Media Cards mailxto.com/tiggatest Social Media Cards Check the below social media card previews...";

  return (
    <div className="flex flex-col md:flex-row max-w-4xl mx-auto  bg-white rounded-lg shadow-md">
      <div className="flex-1">
        <div className="flex flex-col pl-6 pt-6 border-b border-b-slate-200">
          <h2 className="text-lg font-semibold mb-2">
            Customize Social Media Cards
          </h2>
          <p className="text-sm text-blue-500 mb-4">mailxto.com/tiggatest</p>
        </div>

        <div className="flex flex-col p-6 gap-4">
          <UploadImage
            forSocial={true}
            onUpload={(val) => {
              setImgUrl(val);
            }}
          />

          <div className="">
            <label className="block text-gray-700 mb-2" htmlFor="title">
              Title
            </label>
            <input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              value={title}
              type="text"
              id="title"
              className="w-full p-2 border border-slate-200 rounded"
              placeholder="This is a test email too dyesjlkd"
              maxLength="120"
            />
          </div>

          <div className="">
            <label className="block text-gray-700 mb-2" htmlFor="description">
              Description
            </label>
            <textarea
              onChange={(e) => {
                setDesc(e.target.value);
              }}
              value={desc}
              id="description"
              className="w-full p-2 border border-slate-200 rounded resize-none"
              placeholder="Customize Social Media Cards mailxto.com/tiggatest Social Media Cards Check the below social media card previews..."
              // maxLength="240"
              rows={6}
            />
          </div>

          <button className="w-full py-2 px-4 bg-black text-white rounded">
            Save Changes
          </button>
        </div>
      </div>

      <div className="flex-1 mt-6 md:mt-0 ">
        <div className="flex flex-col px-6 pt-6 border-b border-b-slate-200 ">
          <h2 className="text-lg font-semibold mb-2">Social Media Cards</h2>
          <p className="text-sm text-gray-600 mb-4">
            Check the below social media card previews.
          </p>
        </div>

        <div className="md:h-[600px] md:overflow-y-auto">
          <div className="flex flex-col md:border-l md:border-l-slate-200 p-6">
            <div className="mb-4">
              <h3 className="text-sm text-gray-700 mb-2 text-blue-600 font-semibold">
                Facebook Preview
              </h3>
              <div className="border border-slate-200 rounded">
                <div className="bg-gray-200 h-52">
                  <img
                    src={imgUrl}
                    alt="social"
                    className="w-full h-52 object-cover"
                  />
                </div>

                <div className="p-4 bg-slate-100">
                  <p className="text-sm text-gray-700">MAILXTO.COM</p>
                  <p className="font-semibold text-gray-900">
                    {title || initialTitle}
                  </p>
                  <div className="w-full md:w-[300px] truncate text-sm text-gray-600 ">
                    {desc || initialDesc}
                  </div>
                </div>
              </div>
            </div>

            <div className="mb-4">
              <h3 className="text-sm text-gray-700 mb-2 text-blue-600 font-semibold">
                X Preview
              </h3>
              <div className="border border-slate-200 rounded">
                <div className="bg-gray-200 h-52">
                  <img
                    src={imgUrl}
                    alt="social"
                    className="w-full h-52 object-cover"
                  />
                </div>

                <div className="p-4 bg-slate-100">
                  <p className="text-sm text-gray-700">MAILXTO.COM</p>
                  <p className="font-semibold text-gray-900">
                    {title || initialTitle}
                  </p>
                  <div className="w-full md:w-[300px] truncate text-sm text-gray-600 ">
                    {desc || initialDesc}
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="text-sm text-gray-700 mb-2">Twitter Preview</h3>
              <div className="border border-slate-200 rounded">
                <div className="bg-gray-200 h-52">
                  <img
                    src={imgUrl}
                    alt="social"
                    className="w-full h-52 object-cover"
                  />
                </div>

                <div className="p-4 bg-slate-100">
                  <p className="text-sm text-gray-700">MAILXTO.COM</p>
                  <p className="font-semibold text-gray-900">
                    {title || initialTitle}
                  </p>

                  <div className="w-full md:w-[300px] truncate text-sm text-gray-600 ">
                    {desc || initialDesc}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialMediaCardCustomizer;
