import React, { useState, useEffect, useRef } from "react";

import mailxtologo from "../assets/mailxto-logo.svg";
import BurgerMenu from "./icons/BurgerMenu";

const NavigationBar = () => {
  // navigation menu
  const [menuExpanded, setMenuExpanded] = useState(false);

  const myContentRef = useRef(null);

  const burggerMenuHandler = () => {
    setMenuExpanded((isExpanded) => {
      return !isExpanded;
    });
  };

  useEffect(() => {
    const myContent = myContentRef.current;

    if (menuExpanded) {
      myContent.style.maxHeight = `${myContent.scrollHeight}px`;
      myContent.style.opacity = 1;
    } else {
      if (window.innerWidth < 768) {
        myContent.style.maxHeight = `${0}px`;
        myContent.style.opacity = 0;
      }
    }
  }, [menuExpanded]);

  return (
    <header
      className={`w-full flex justify-center md:items-center sticky relative z-[100]
      top-0 left-0  ${"h-[61px] sm:h-[73px] md:h-[69px] lg:h-[79px]"}`}
    >
      <div
        className={`w-full flex justify-center md:items-center absolute
      top-0 left-0  border-b border-b-slate-200 backdrop-blur-sm bg-white/90  ${
        !menuExpanded && "h-[61px] sm:h-[73px] md:h-[69px] lg:h-[79px]"
      }`}
      >
        <div className="w-[90%] flex  flex-wrap justify-between items-center   lg:max-w-[1500px]  max-h-auto">
          <div className=" flex items-center justify-between w-full md:w-auto">
            <div className="flex justify-between items-center w-full relative h-[61px] sm:h-[73px] md:h-[69px] lg:h-[79px]">
              <img
                src={mailxtologo}
                alt="mailxto logo"
                className="w-20 sm:w-24 md:w-32 lg:w-40"
              />

              <div className="flex items-center md:hidden ml-1 space-x-3">
                <button onClick={burggerMenuHandler}>
                  <BurgerMenu styles={"w-8 h-8 text-gray-600"} />
                </button>
              </div>
            </div>
          </div>

          <div
            className={` flex items-center relative  ${
              menuExpanded ? "py-2" : "py-0"
            } `}
          >
            <div
              className={`flex items-center  md:space-x-6 lg:space-x-8 h-full mr-0 md:mr-0 lg:mr-0 mt-0`}
            >
              <div
                ref={myContentRef}
                className={`${
                  menuExpanded ? "duration-100 " : "max-h-0"
                } overflow-hidden md:overflow-visible transition-max-height md:duration-0 md:max-h-full`}
              >
                <ul
                  className={`flex flex-col justify-center md:flex-row space-y-2 md:space-y-0 md:space-x-6  text-gray-600 w-full lg:w-auto `}
                >
                  <li className="flex items-center">Home</li>
                  <li className="flex items-center">Add to Calender</li>
                  <li className="flex items-center">Collect RSVPs</li>
                  <li className="flex items-center">Features</li>
                  <button>Login</button>
                  <button>Go Premium</button>
                </ul>
              </div>
            </div>
            <div className="hidden md:inline lg:hidden w-full absolute right-0"></div>
          </div>
        </div>{" "}
      </div>
    </header>
  );
};

export default React.memo(NavigationBar);
