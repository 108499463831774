import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import context from "../../store/context";
import AnalyticsIcon from "../icons/Analytics";
import CopyIcon from "../icons/Copy";
import EditIcon from "../icons/Edit";
import ImageIcon from "../icons/Image";
import PaintIcon from "../icons/Paint";
import QrIcon from "../icons/Qr";

const LinksCard = ({ linkInfo, getClickedLinkData }) => {
  const { setIsModalOpen } = useContext(context);

  const navigate = useNavigate();
  return (
    <div className="border border-slate-200 rounded-md bg-white p-5 flex flex-col divide-y divide-y-slate-200 ">
      <div className="pb-5 flex flex-col gap-3">
        <div className="flex items-center gap-1 sm:gap-2">
          <p className="text-normal sm:text-xl font-semibold">
            {linkInfo?.subject}
          </p>
          <button
            className="transform hover:scale-95 transition-transform text-slate-700 hover:text-black"
            onClick={() => {
              setIsModalOpen(true);
              getClickedLinkData(linkInfo, "edit_form");
            }}
          >
            <EditIcon />
          </button>
        </div>
        <div className="flex items-center text-sm sm:text-normal gap-2">
          <p>{linkInfo?.custom_slug || linkInfo?.slug}</p>
          <button className="transform hover:scale-95 transition-transform text-slate-700 hover:text-black">
            <CopyIcon />
          </button>
        </div>
      </div>
      <div className="flex items-center justify-between pt-3">
        <div className="flex items-center gap-4">
          <button
            className="transform hover:scale-95 transition-transform text-slate-700 hover:text-black"
            onClick={() => {
              setIsModalOpen(true);
              getClickedLinkData(linkInfo, "QR");
            }}
          >
            <QrIcon />
          </button>
          <button
            className="transform hover:scale-95 transition-transform text-slate-700 hover:text-black"
            onClick={() => {
              setIsModalOpen(true);
              getClickedLinkData(linkInfo, "socials");
            }}
          >
            <ImageIcon />
          </button>
          <button
            className="transform hover:scale-95 transition-transform text-slate-700 hover:text-black"
            onClick={() => {
              navigate("/dashboard/themes");
            }}
          >
            <PaintIcon />
          </button>
        </div>
        <button className="bg-gray-200 text-black px-3 sm:px-5 py-2 rounded-full flex items-center gap-1 sm:gap-3 text-sm sm:text-normal">
          <AnalyticsIcon /> {linkInfo?.click_count || 0} clicks
        </button>
      </div>
    </div>
  );
};

export default LinksCard;
