import QuestionCircleIcon from "./icons/QuestionCircle";

const AnalysisLabel = ({ label }) => {
  return (
    <div className="flex items-center gap-2">
      <label className="font-semibold">{label}</label>
      <button className="text-slate-600">
        <QuestionCircleIcon />
      </button>
    </div>
  );
};

export default AnalysisLabel;
